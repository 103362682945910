@import "styles/mixin.scss";
@import "styles/global.scss";

.service-cards-link {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1 {
    text-align: center;
    margin: 10px;
    color: $global-color;
    font-family: $global-font;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
  }

  /* Main CSS */
  &-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;

    .service-cards-link-container {
      position: relative;
      text-align: center;
      justify-content: center;
      align-items: center;
      @include magic-border(3px, $global-color, 0.15s, 0);

      &:hover {
        h3 {
          color: $global-color;
        }

        img {
          opacity: 0.5;
        }
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #2e4599;
        font-size: 30px;
        margin: 0px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.75;
        &:hover {
          cursor: pointer;
        }
      }

      &:nth-child(3),
      &:nth-child(6) {
        grid-row: span 2;
      }

      &:nth-child(4) {
        grid-column: span 2;
      }

      &:nth-child(7) {
        grid-column: span 2;
        grid-row: span 2;

        @media only screen and (max-width: 1070px) {
          grid-column: span 1;
          grid-row: span 1;
        }

        @media only screen and (max-width: 790px) {
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      &:nth-child(9) {
        grid-column: span 1;
        grid-row: span 1;

        @media only screen and (max-width: 1310px) {
          grid-column: span 2;
        }

        @media only screen and (max-width: 1070px) {
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      @media only screen and (max-width: 640px) {
        grid-column: span 3;
        grid-row: span 1;
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(9) {
          grid-column: span 3;
          grid-row: span 1;
        }
      }
    }
  }
}
