@import "styles/global.scss";

.contact-container {
  display: flex;
  width: 100%;
  height: 90vh;

  .phone-container,
  .email-container {
    text-transform: uppercase;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3,
    h2 {
      margin: 0px 0px 5px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      margin: 25px auto;
      font-size: 18px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .phone-container {
    background: $global-color;
    color: white;
  }

  .email-container {
    color: $global-color;
    background: white;

    .email-links {
      text-align: left;
      margin: 0px auto 10px;

      a {
        font-weight: 900;
        p {
          margin: 30px 0px 10px;
        }
        span {
          font-weight: 500;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    .phone-container,
    .email-container {
      width: 100vw;
      height: 45vh;
    }
  }
}
