// general
@import "styles/mixin.scss";
@import "styles/global.scss";

.responsive-image-container {
  img {
    height: auto;
    width: 100%;
  }
}

.service-page {
  display: flex;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &-container {
    width: 65vw;
    padding: 0px 20px;

    @media screen and (max-width: 900px) {
      width: 90vw;
    }
  }

  &-navbar {
    width: 35vw;
    padding: 20px;

    &-card {
      &-container {
        position: relative;
        text-align: center;
        width: 80%;
        margin: 0px auto 10px;
        cursor: pointer;
        @include magic-border(3px, $global-color, 0.15s, 0);

        &::before {
          height: 100%;
        }

        h3 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -40%);
          margin: 0px;
        }

        img {
          opacity: 0.75;
          width: 100%;
          height: auto;
          cursor: pointer;
        }

        &:hover > img {
          opacity: 0.5;
        }
      }
    }

    &-contact {
      &-btn {
        margin: 10px;
        a {
          text-decoration: none;
          display: block;
          padding: 10px;
          background: #003366;
          color: white;
          font-weight: 900;
          margin: 20px 0px;
          border-radius: 5px;
          border: 3px solid #003366;

          &:hover {
            color: #003366;
            background: white;
          }
        }
      }

      &-icons {
        margin: 20px 0px;

        a {
          padding: 5px;
        }
      }
    }

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}

.service-page-hero-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service-page-hero-title {
  max-width: 16em;
  margin: 0;
  font-size: 12vh;
  font-weight: bold;
  line-height: 0.9;

  @media only screen and (min-width: 32em) {
    font-size: 16vh;
  }
}

.service-page-hero-footer {
  display: flex;
  margin-bottom: 1em;

  .button {
    padding: 0.5em 0.67em;
    color: black;
    text-decoration: none;
    border: 0.1em solid white;
  }

  .button-primary {
    color: black;
    background-color: white;
  }
}

.service-page-about {
  width: 80%;
  margin: 0 auto;

  h2 {
    font-size: 35px;
  }

  &-description {
    text-align: left;
    font-size: 20px;
  }
}

.service-page-slider {
  width: 90%;
  margin: auto;
}

.service-page-list {
  width: 100%;
  margin: auto;

  h3 {
    font-size: 30px;
  }

  .service-page-tags {
    justify-content: flex-start;
    width: 80%;
    margin: auto;

    &-row {
      display: flex;
      padding: 5px 0px;
      align-items: self-end;
    }

    &-tick {
      margin-right: 10px;
      padding: 0px;
    }

    &-point {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1080px) {
    .service-page-tags-container {
      justify-content: left;
    }
  }

  @media screen and (max-width: 640px) {
    width: 90vw;
    .service-page-tags-container {
      justify-content: flex-start;
    }
  }
}
