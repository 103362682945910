@import "./grid.scss";
@import "aos/dist/aos.css";
@import "styles/global.scss";
@import "swiper/css";
@import "swiper/css/navigation";

html,
body {
  height: 100%;
  margin: 0;
  font-family: $global-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $global-color;
  color: $global-color;
}

main {
  background: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.responsive-image {
  width: 100%;
  height: auto;
}

:root {
  --swiper-theme-color: $global-color;
}

.swiper-button-prev,
.swiper-button-next {
  background: white;
  padding: 5px;
}
