@import "styles/global.scss";

.about-quote {
  &-wrapper {
    background: white;
    display: flex;
    height: 100%;
    padding: 0 20px;
  }

  &-container {
    position: relative;
    max-width: 620px;
    margin: 80px auto;
    align-self: center;

    h1 {
      font-family: $global-font;
      position: relative;
      color: $global-color;
      font-size: 44px;
      font-weight: normal;
      line-height: 1;
      margin: 0;
      border: 2px solid $global-color;
      border: solid 2px;
      border-radius: 20px;
      padding: 25px;

      @media screen and (max-width: 480px) {
        font-size: 32px;
      }

      &:after {
        content: "";
        position: absolute;
        border: 2px solid $global-color;
        border-radius: 0 50px 0 0;
        width: 60px;
        height: 60px;
        bottom: -62px;
        left: 50px;
        border-bottom: none;
        border-left: none;
        z-index: 3;
      }

      &:before {
        content: "";
        position: absolute;
        border: 2px solid white;
        width: 80px;
        bottom: -3px;
        left: 50px;
        z-index: 2;
      }

      @media all and (min-width: 600px) {
        font-size: 48px;
        line-height: 1.2;
      }
    }

    h4 {
      position: relative;
      color: $global-color;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding-top: 15px;
      z-index: 1;
      margin-left: 150px;
      padding-left: 12px;

      &:first-letter {
        margin-left: -12px;
      }
    }
  }
}
