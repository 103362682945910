.case-study-page {
  .case-study-container {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: center;

    &:nth-of-type(even) {
      flex-flow: row-reverse;
      @media screen and (max-width: 835px) {
        flex-direction: column-reverse;
      }
    }

    .case-study-grid,
    .case-study-caption {
      width: 45vw;
    }

    .case-study-body {
      h2 {
        font-size: 16px;
      }
    }

    .case-study-grid {
      width: 25%;
      .site-col-12 {
        padding: 5px 0px 0px 2.5px;
      }
      .site-col-4 {
        padding: 5px 2.5px;
        padding-right: 0px;

        img {
          width: 100%;
          height: auto;
        }
      }

      @media screen and (max-width: 835px) {
        width: 80%;
      }
    }

    .case-study-caption {
      padding: 10px;

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
        margin: 0px;
      }

      p {
        text-align: left;
        margin: 8px 0px 24px;
      }

      @media screen and (max-width: 835px) {
        width: 80%;
      }
    }

    @media screen and (max-width: 835px) {
      flex-direction: column-reverse;
    }
  }
}
