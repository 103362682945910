.terms-container {
  padding: 20px 100px;
  line-height: 30px;

  h1 {
    font-size: 25px;
  }

  p,
  li {
    text-align: left;
  }

  ul {
    padding: 0px;
    padding-left: 10px;
  }

  li {
    padding: 0px;
  }

  &-title {
    font-size: 40px !important;
    padding-top: 0px;
    margin: 0px;
    text-transform: uppercase;
  }
}
